export const EMAIL_VALIDATION_REGEX =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9](?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{0,62}[a-zA-Z0-9])?@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/

export const ADDRESS_VALIDATION_REGEX =
  /^(?=.*[-!$%^&*()_+|~=`°"'{}[\]:\\/;<>?@#]).*$/

export const NAME_VALIDATION_REGEX =
  /^[A-Za-z'\u0027\u0060\u2019\u002D\u2013\s]+$/

export const CONSECUTIVE_SPECIAL_CHARS_REGEX = /[!@#$^&*()+-,.?":{}|<>/'=;]{2}/

export const CONSECUTIVE_FORBIDDEN_CHARACTERS_REGEX =
  /[!#$^&*()?/,<>"';:{}|=]{1,}/

export const PASSWORD_VALIDATION_REGEX_SPECIAL_CHAR =
  /^(?=.*[-!$%^&*()_+|~=`°"'{}[\]:\\/;<>?,.@#]).*$/

export const PASSWORD_VALIDATION_REGEX_NUMBER = /^(?=.*?[0-9]).{7,}$/

export enum MODE {
  email = 'verifyEmail',
  resetPassword = 'resetPassword',
}

export enum SUCCESS_PAGES {
  verificationEmail = 'verification-email',
  forgotPassword = 'forgot-password',
  newPassword = 'new-password',
  declineContract = 'decline',
}

export const LOCAL_ID_CONTRACT = 'contractId'

export const EMBEDDED_ACCOUNT_TYPE = 'LightAccount'

export const SIGNINMETHOD = {
  PASSWORD: 'password',
  GOOGLE: 'google.com',
}

export enum WALLET_TYPES {
  Embedded = 'EMBEDDED',
  External = 'EXTERNAL',
}

export enum FIREBASE_ERRORS {
  twoFactorRequired = 'auth/multi-factor-auth-required',
  wrongPAssword = 'auth/wrong-password',
  invalidCode = 'auth/invalid-verification-code',
  timeout = 'auth/totp-challenge-timeout',
  loginRequired = 'auth/requires-recent-login',
}

export enum TRANSACTION_TYPE {
  PARTIAL_DEPOSIT = 'Partial Deposit',
  PARTIAL_WITHDRAWAL = 'Partial Withdrawal',
  CLAIM = 'Claim',
  SUBMIT = 'Submit',
  APPROVE = 'Approve',
  DEPOSIT_ESCROW = 'Deposit Escrow',
  DEPOSIT_PREPAYMENT = 'Deposit Prepayment',
  PAYMENT = 'Payment',
  WITHDRAW = 'Withdraw',
  RETURN_REQUESTED = 'Return Requested',
  CANCEL_RETURN = 'Cancel Return',
  RETURN_APPROVE = 'Approve Return',
  DISPUTE_CREATED = 'Create Dispute',
  DISPUTE_RESOLVED = 'Resolve Dispute',
  REFILL = 'Refill',
  ADMIN_APPROVE = 'Admin Approve',
  COVERAGE_FEE = 'Coverage Fee',
  CLAIM_FEE = 'Claim Fee',
  CLAIM_ALL = 'Claim All',
}

export enum REST_ENDPOINTS {
  INVOICE_ARCHIVE = '/invoice/archive',
  INVOICE_CSV = '/invoice/csv',
}

export enum CURRENCY {
  USDC = 'USDC',
  USDT = 'USDT',
  DAI = 'DAI',
  DOLLAR = '$',
}

export enum KYC_STATUS {
  UNVERIFIED = 'unverified',
  APPROVED = 'approved',
  IN_PROGRESS = 'in_progress',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  MANUAL_CHECK = 'manual_check',
  MANUAL_REJECTED = 'manual_rejected',
}
